@import "../../theme/main.scss";
.chat-list {
    &__wrapper {
        height: 100px;
        border-bottom: 2px solid #dddddd;
        padding: 0 40px;
        cursor: pointer;
        &:last-child {
            border: none;
        }
    }
    &__images {
        img {
            max-width: 58px;
            max-height: 58px;
        }
        div {
            height: 45px;
            width: 45px;
        }
        :nth-child(2) {
            margin-left: -20px;
        }
    }
    &__content {
        &-name {
            h3 {
                color: #000000;
                font-size: 18px;
                font-family: $primary-font-family;
                text-transform: capitalize;
                margin: 0;
            }
        }
        &-description {
            font-size: 14px;
            color: #000000;
            font-family: $primary-font-family;
            font-weight: 300;
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
        }
    }
    &__action {
        p {
            font-size: 14px;
            color: #979797;
            font-weight: 300;
        }
        .icon-1 {
            color: #e45858;
            font-size: 12px;
        }
        .icon-2 {
            color: #6246ea;
            font-size: 22px;
            font-weight: 100;
        }
    }
}

@media screen and (max-width: 1390px) {
    .chat-list {
        &__wrapper {
            padding: 0 20px;
        }
        &__images {
            img {
                max-width: 40px;
                max-height: 40px;
            }
        }
        &__content {
            &-name {
                h3 {
                    font-size: 14px;
                }
            }
            &-description {
                font-size: 12px;
                width: 100px;
            }
        }
    }
}
