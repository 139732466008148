@import "../../theme/main.scss";

.report {
    &__detail {
        margin-bottom: 50px;
    }
    &-detail {
        margin-top: 38px;
    }
    &__info {
        &-title {
            svg {
                font-size: 12px;
                color: #6246ea;
                margin: auto;
            }
            h2 {
                font-weight: 500;
                font-size: 22px;
                line-height: 20px;
                letter-spacing: -0.24px;
                color: #382745;
                margin-bottom: 0;
            }
            p {
                font-weight: 300;
                font-size: 22px;
                line-height: 20px;
                letter-spacing: -0.24px;
                color: #382745;
                margin: 0;
            }
            .resolve {
                color: #6fcf97;
            }
            .appeal {
                color: #f2c94c;
            }
        }

        &-content {
            h2 {
                font-weight: 500;
                font-size: 17px;
                line-height: 20px;
                letter-spacing: -0.24px;
                color: #3b3b3b;
            }
            p {
                font-weight: normal;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.24px;
                color: #3b3b3b;
                max-width: 600px;
                width: 90%;
                overflow-wrap: break-word;
            }
        }
    }
    &__content {
        &-user {
            h3 {
                font-weight: 500;
                font-size: 17px;
                line-height: 20px;
                letter-spacing: -0.24px;
                color: #3b3b3b;
            }
            .match-card__wrapper {
                margin-left: 0 !important;
                p {
                    padding-left: 15px !important;
                }
            }
        }
    }
}
.report-appeal-coment {
    .comment__info-remarks {
        max-width: 461px !important;
        height: 533px !important;
        padding: 20px;
    }
}
// .dropdown-btn {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0;
//     min-width: 105px;
//     padding: 10px 0 10px 10px;
//     width: fit-content;
//     background: #e4e4fc !important;
//     border: none !important;
//     border-radius: 12px !important;
//     color: #6246ea;
//     font-size: 15px;
//     &:active,
//     &:focus {
//         color: #6246ea;
//     }
// }
// .dropdown-menu {
//     background: #e4e4fc;
//     border-radius: 15px;
//     width: 162px;
//     border: none;
// }
.option-dropdown {
    margin-right: 15px;
    margin-left: 200px;

    .Dropdown-menu {
        top: 60px !important;
    }
    .Dropdown-control {
        min-width: 105px;
        background: #e4e4fc;
        border: none;
        border-radius: 12px;
        color: #6246EA;
        display: flex;
        align-items: center;
        padding-right: 8px;
    }
    .Dropdown-placeholder {
        font-family: 'Lato';
        padding-right: 30px;
        color: #6246ea;
        font-size: 17px;
        line-height: 18px;
        font-weight: normal;
    }
    .Dropdown-menu {
        min-width: 170px;
        background: #e4e4fc !important;
        position: absolute;
        border: none;
        border-radius: 12px !important;
        &:hover {
            background-color: red;
        }
    }
    .Dropdown-option {
        font-size: 15px;
        line-height: 18px;
        color: #6246ea;
        display: flex;
        align-items: center;
        &:after {
            content: "";
            position: absolute;
            background-image: url("../../assets//images/Vector.png");
            height: 12px;
            width: 9px;
            right: 10px;
            background-repeat: no-repeat;
        }
        &:hover {
            background-color: #c4c4f7;
            color: #6246ea;
        }
        &.is-selected {
            background-color: #c4c4f7;
            color: #6246ea;
        }
    }
}
.report-detail__match-card {
    .match-card__wrapper {
        width: 142px;
        height: 81px;
        margin-right: 43px !important;
        p {
            font-family: $secondary-font-family !important;
        }
    }
}

.loader-container {
    height: 340px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 770px) {
    .report {
        &__info {
            &-title {
                h2 {
                    font-size: 16px;
                }
                p {
                    font-size: 16px;
                }
            }
        }
        &-detail {
            margin-top: 20px;
        }
        &-appeal {
            h4 {
                font-size: 18px;
            }
        }
    }
    .option-dropdown {
        margin-left: 0px;
        margin-bottom: 10px;
    }
}
