.accordian {
    h2 {
        font-weight: normal;
        font-size: 17px;
        line-height: 110%;
        color: #6246ea;
    }
    p {
        font-size: 15px;
        font-weight: normal;
    }
    .accordion__button {
        position: relative;
        padding: 20px 15px;
        background: transparent;
        border-bottom: 2px solid #dddddd;
        color: #6246ea;
        box-shadow: none !important;
        &:hover {
            background: transparent;
        }

        &::before {
            position: absolute;
            right: 20px;
            top: 38%;
        }
    }
    .accordion {
        border: none;
        border-radius: 0;
    }
    .accordion__button[aria-expanded="true"] {
        border-bottom: none;
        h2 {
            color: #e45858;
        }
        &::before {
            color: #e45858;
        }
    }
}
.accordian-inner-content {
    h6 {
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #6246ea;
    }
    p {
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #444444;
        padding-left: 5px;
    }
}
.filter-sub-heading {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #e45858;
}
.accordian-sub-heading {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #6246ea;
    margin-bottom: 15px;
}
.filter-btn-wrapper {
    button {
        min-width: fit-content !important;
    }
}
