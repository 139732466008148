.previous-chat {
    &__wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        height: calc(100vh - 300px);
        overflow: hidden;
        margin-bottom: 50px;
    }
    &__info {
        width: 40%;
    }
    &__message {
        width: 60%;
    }
    &__header {
        height: 70px;
        border-bottom: 2px solid #dddddd;
        position: sticky;
        top: 0;
        background-color: #fff;
    }
    &__body {
        height: calc(100vh - 370px);
        min-height: 300px;
        overflow-y: auto;
    }
}
.right-border {
    border-right: 2px solid #dddddd;
}
@media screen and (max-width: 780px) {
    .mobile-wrapper {
        .previous-chat {
            &__info {
                width: 100%;
                border: none;
            }
            &__message {
                display: none;
            }
        }
    }
    .mobile-wrapper-detail {
        .previous-chat {
            &__info {
                display: none;
            }
            &__message {
                width: 100%;
            }
        }
    }
    .right-border {
        border: none;
    }
}
