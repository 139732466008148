@import "../../theme/main.scss";

.user-profile {
    &__wrapper {
        margin-bottom: 50px;
    }
    &__info {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        max-width: 550px;
        width: 100%;
        height: 593px;
        padding: 20px;
        h3 {
            font-weight: normal;
            font-size: 22px;
            line-height: 110%;
            color: #241762;
            font-family: $secondary-font-family !important;
        }
        P {
            font-weight: normal;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #342222;
            margin: 0;
            text-align: center;
        }
        button {
            min-width: fit-content;
            padding: 0 30px !important;
        }
        img {
            max-width: 131px;
            max-height: 138px;
        }
    }
    &__stats {
        width: 80%;
        p {
            font-weight: normal;
            font-size: 15px;
            line-height: 110%;
            color: #000000;
        }
        img {
            width: 32px;
        }
    }
    &__about {
        h4 {
            font-weight: normal;
            font-size: 17px;
            line-height: 110%;
            color: #6246ea;
        }
        p {
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #444444;
        }
    }

    &__accordian {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        max-width: 550px;
        width: 100%;
        height: min-content;
    }
}
@media screen and (max-width: 730px) {
    .user-profile {
        &__info {
            p {
                font-size: 14px;
            }
        }
        &__stats {
            width: 100%;
            p {
                font-size: 12px;
            }
        }
    }
}
