@import "../../theme/main.scss";

.user-deatil {
    &__wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        height: calc(100vh - 300px);
        overflow: auto;
        max-width: 574px;
        width: 95%;
        margin-bottom: 50px;
        overflow-x: hidden;
    }
    &__info {
        padding: 15px;
        &-img {
            width: 100px;
            height: 100px;
        }
        &-detail {
            h3 {
                color: #241762;
                font-weight: normal;
                font-size: 22px;
                font-family: $secondary-font-family !important;
                margin: 0;
            }
            p {
                font-size: 15px;
                color: #000000;
                margin: 0;
                font-weight: normal;
            }
            border-right: 1px solid #dddddd;
            padding-right: 35px;
        }
        &-stats {
            P {
                font-size: 15px;
                color: #000000;
                margin: 0;
                font-weight: normal;
            }
        }
    }
    &__about {
        padding: 15px 15px 0 15px;
        h2 {
            font-weight: normal;
            font-size: 17px;
            line-height: 110%;
            color: #6246ea;
        }
        P {
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
        }
    }
    &__accordian {
        h2 {
            font-weight: normal;
            font-size: 17px;
            line-height: 110%;
            color: #6246ea;
        }
        .accordion__button {
            position: relative;
            padding: 20px 15px;
            background: transparent;
            border-bottom: 2px solid #dddddd;
            color: #6246ea;
            box-shadow: none !important;
            &:hover {
                background: transparent;
            }

            &::before {
                position: absolute;
                right: 20px;
                top: 38%;
            }
        }
        .accordion {
            border: none;
            border-radius: 0;
        }
    }
}
.match-filter {
    h3 {
        font-weight: normal;
        font-size: 17px;
        line-height: 17px;
        letter-spacing: -0.24px;
        color: #444444;
    }
    &__selecter {
        padding-left: 20px;
        p {
            font-size: 17px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #444444;
            font-weight: normal;
            padding-left: 10px;
        }
    }
}
// ul.ks-cboxtags li input[type="checkbox"]:focus + label {
//     border: 1px solid #6246ea;
// }
.match-manual {
    .match-card {
        &__wrapper {
            width: 216px;
            height: 101px;
            margin-bottom: 35px !important;
            img {
                width: 82px;
                height: 86px;
            }
            p {
                padding-left: 17px;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .match-btn {
        min-width: fit-content !important;
    }
}
