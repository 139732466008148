.report__info {
    &-detail {
        h4 {
            // font-weight: normal;
            font-size: 15px;
            line-height: 15px;
            letter-spacing: -0.24px;
            color: #382745;
        }
        p {
            font-weight: 300;
            font-size: 15px;
            line-height: 15px;
            letter-spacing: -0.24px;
            color: #382745;
        }
    }
}
