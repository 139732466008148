.suspendModal {
    border-radius: 13px;
    max-width: 475px;
    width: 90%;
    // height: 697px;
    padding: 10px 0 20px 0;
}
.duration {
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
}
.suspend__modal {
    h4 {
        font-weight: normal;
        font-size: 17px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #3b3b3b;
    }

    ul.filter-list__radio li {
        display: inline;
    }
    ul.filter-list__radio li label {
        display: inline-block;
        border: 1px solid #6246ea;
        background-color: transparent;
        color: #6246ea;
        border-radius: 15px;
        white-space: nowrap;
        margin: 3px 5px 10px 5px;
        font-weight: normal;
        font-size: 17px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        transition: all 0.2s;
    }

    ul.filter-list__radio li label {
        padding: 5px 12px;
        cursor: pointer;
    }

    ul.filter-list__radio li input[type="radio"]:checked + label {
        border: transparent;
        background-color: #e4e4fc;
        color: #6246ea;
        transition: all 0.2s;
    }

    ul.filter-list__radio li input[type="radio"] {
        display: absolute;
    }
    ul.filter-list__radio li input[type="radio"] {
        position: absolute;
        opacity: 0;
    }
    textarea {
        background: #ffffff;
        border: 1px solid #dddddd !important;
        box-sizing: border-box;
        border-radius: 15px;
        padding: 15px !important;
        font-weight: 500;
        font-size: 15px;
        color: #363636;
        max-width: 415px;
        height: 256px !important;
        width: 100%;
        margin: 0 auto;
        resize: none;
        &:focus,
        &:active {
            outline: none;
        }
        &::placeholder {
            color: #3b3b3b;
        }
    }
    .comment-limit {
        position: absolute;
        bottom: 29px;
        right: 15px;
        font-weight: normal;
        font-size: 8px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #bdbdbd;
    }
    button {
        width: 257px;
        height: 50px;
    }
}
@media screen and (max-width: 650px) {
    .suspend__modal {
        textarea {
            width: 90%;
        }
        .comment-limit {
            right: 25px;
        }
    }
}
