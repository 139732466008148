.report {
    &__info {
        min-width: 60%;
    }
    &__content {
        min-width: 30%;
        &-chat {
            background: #ffffff;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
            border-radius: 15px;
            max-width: 372px;
            width: 100%;
            height: 419px;
            padding: 0px 20px 20px 20px;
        }
        &-title {
            position: sticky;
            top: 0;
            background-color: #fff;
            p {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: #4c4c66;
                padding-top: 10px;
            }
            svg {
                font-size: 11px;
            }
        }
        &-message {
            overflow-x: hidden;
            .receiver-chat {
                margin: 0 0 15px 0 !important;
                &__image figure {
                    min-height: 0 !important;
                }
                h2 {
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 1px;
                    text-transform: capitalize;
                    color: #9d9d9d;
                }
                p {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.352px;

                    color: #3b3b3b;
                }
                &__content {
                    padding: 10px;
                }

                align-items: flex-start !important;
            }
            .sender-chat {
                margin: 0 !important;
                &__image figure {
                    min-height: 0 !important;
                }

                p {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.352px;
                    color: #f2f2f2;
                }
                &__content {
                    padding: 10px;
                }
            }
        }
    }
}
