@import "../../theme/main.scss";

.verificationModal {
    max-width: 1232px;
    width: 90%;
    max-height: 737px;
    height: 90%;
    overflow: auto;
    border-radius: 15px;
}
.ConfirmModal {
    max-width: 475px;
    width: 90%;
    height: 328px;
    border-radius: 15px;
}
.confirm-modal {
    height: 100%;

    p {
        font-size: 22px;
        line-height: 30px;
        color: #3b3b3b;
        max-width: 290px;
        font-weight: normal;
        font-family: $primary-font-family;
    }
    .confrim-btn {
        min-width: 85px !important;
    }
}
.video-playicon {
    position: absolute;
    top: 47%;
    left: 52%;
}

.verifiy__modal {
    .play-btn-holder {
        position: relative;
        .play-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 99;
        }
    }
    min-height: 600px;
    &-info {
        padding-left: 100px;
        h3 {
            font-size: 22px;
            line-height: 110%;
            color: #241762;
            font-family: $secondary-font-family;
        }
        p {
            font-size: 17px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #342222;
            margin: 0;
            font-weight: normal;
        }
        img {
            width: 227px;
            height: 239px;
            border-radius: 15px;
        }
    }
    &-images {
        width: min-content;
        height: 450px;
        // overflow: auto;
        padding-right: 40px;
        img {
            margin-bottom: 18px;
            min-width: 300px;
            width: 90%;
            max-height: 225px;
            object-fit: cover;
        }
    }
    .video-thumnail {
        width: 301px;
        height: 449px !important;
        object-fit: cover;
    }
}
.verification-images {
    width: 338px;
    height: 236px;
}

.profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 10%;
}

.no-pending-center-wrap {
    min-height: calc(100vh - 264.11px);
    align-items: center;
    p,h4 {
        color: #979797;
    }
}

@media screen and (max-width: 780px) {
    .verifiy__modal {
        &-info {
            padding-left: 0;
            img {
                width: 150px;
                height: 150px;
            }
        }
        &-images {
            padding-right: 0;
        }
        .video-thumnail {
            width: 250px;
            height: 300px;
        }
    }
    .verification-images {
        width: 238px;
        height: 236px;
    }
}

.w-80 {
    width: 80%;
}

.loader-padding {
    padding-bottom: 22px;
}


@media screen and (max-width: 767px) {
    .no-pending-center-wrap {
        min-height: calc(100vh - 157px);
    }
}