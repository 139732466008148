@import "../../theme/main.scss";

.user-summary {
    &__wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        max-width: 483px;
        width: 100%;
        height: 685px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 50px;
        transition: transform 1s linear;
        right: 10%;
        top: 0;
    }
    &__info {
        padding-left: 20px;
        img {
            width: 92px;
            height: 97px;
        }
        h3 {
            font-weight: normal;
            font-size: 22px;
            line-height: 110%;
            color: #3b3b3b;
            font-family: $secondary-font-family !important;
        }
        P {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #3b3b3b;
            margin: 0;
        }
    }
    &__detail {
        border-bottom: 2px solid #dddddd;
        p {
            font-weight: normal;
            font-size: 15px;
            line-height: 110%;
            color: #000000;
            padding-top: 10px;
        }
    }
    &__match {
        border-bottom: 2px solid #dddddd;
        padding: 30px 37px;
    }
    &__chats {
        padding: 30px 37px;
    }
    &__title {
        font-weight: normal;
        font-size: 17px;
        line-height: 110%;
        color: #6246ea;
    }
}
.exit-icon {
    cursor: pointer;
    color: #241762;
}
.user-detail-icon {
    width: 38px;
    height: 38px;
    &-chat {
        width: 34.5px;
        height: 32.39px;
    }
}

@media screen and (max-width: 680px) {
    .user-summary {
        &__detail {
            p {
                font-size: 12px;
            }
        }
        &__title {
            font-size: 13px;
        }
    }
}
.content_fixed {
    position: fixed;
    transform: translate(5%,5%);
}
