@import "../../theme/main.scss";
.input-feild {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    margin-bottom: 25px;
    padding: 15px;
    border: none;
    border-radius: 13px;
    backdrop-filter: blur(54.3656px);

    .border_red {
        border: 1px solid #ff0000 !important;
    }

    .input-text {
        width: calc(100% - 14px);
        height: 100%;
    }
    .icon {
        font-size: 16px;
    }
    .icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #9d95b0;
        margin-right: 10px;
        position: absolute;
    }
    .eyeprop {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
    }

    input {
        background-color: transparent;
        border: none;
        font-size: 17px;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #828282;
        font-weight: normal;
        font-family: $primary-font-family;
        padding: 0 15px 0 30px;

        &:focus,
        &:hover {
            outline: none;
            border: none;
        }
    }
}
