.signup {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__form {
        max-width: 327px;
        width: 95%;
        text-align: center;
        margin-top: 110px;
        z-index: 99;
        a {
            padding: 7px 80px !important;
        }
    }
}
.auth-logo {
    width: 80%;
    max-width: 330px;
    margin: 0 auto;
}
