@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");
$primary: #6246ea;
$primary-dark: #ecb50f;
$secondary: #282828;
$text-color: #0a0a0a;
$black: #0a0a0a;
$border-color-primary: #dadada;
$listing-right-panel: #000000;
$popup-bg: #0d0d0d;
$header-height: 54px;
$header-height-desktop: 44px;
$footer-height: 65px;
$footer-height-desktop: 50px;
$min-content-height: calc(100vh - #{$header-height});
$min-content-height-desktop: calc(100vh - #{$header-height-desktop});
$min-height: 500px;

$primary-font-family: "lato", sans-serif;
$secondary-font-family: "Playfair Display", serif;
