.active-chat {
    &__wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        height: calc(100vh - 240px);
        margin-bottom: 50px;

        position: relative;
        overflow: hidden;
    }
    &__info {
        width: 40%;
        // border-right: 2px solid #dddddd;
    }

    &__message {
        width: 60%;
    }
    &__header {
        height: 70px;
        border-bottom: 2px solid #dddddd;
        position: sticky;
        top: 0;
        background-color: #fff;
    }
    &__body {
        height: calc(100vh - 370px);
        // min-height: 380px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &__footer {
        height: 60px;
        border-top: 2px solid #dddddd;
        position: sticky;
        bottom: 0;
        background-color: white;
        z-index: 999999;
        P {
            color: #9d9d9d;
            font-size: 9px;
            margin: 0;
        }
        svg:not(.send-message) {
            color: #9d9d9d;
            font-size: 18px;
            cursor: pointer;
            position: relative;
        }
        .activate {
            color: #6246ea !important;
            & path {
                stroke: #6246ea;
            }
        }
        .non-activate {
            & path {
                stroke: #9d9d9d;
            }
        }
    }
    &__input {
        border: 2px solid #dddddd;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 3px 100px 3px 20px;
        height: 35px;
        &:focus {
            outline: none;
        }
    }
    &__file {
        right: 80px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        input {
            opacity: 0;
            position: absolute;
            font-size: 0;
            cursor: pointer;
        }
    }
}

.badge {
    position: absolute;
    top: -25px;
    left: 35px;
    border-radius: 50% !important;
    background-color: #e45858;
    color: white;
    font-size: 9px !important;
    height: 15px;
    width: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.badge-2 {
    top: -25px;
    left: 44px;
}
.send-message {
    position: absolute;
    right: 40px;
    cursor: pointer;
}
.right-border {
    border-right: 2px solid #dddddd;
}
.chat-image-preview-container {
    position: absolute;
    padding: 10px;
    z-index: 999;
    bottom: 50px !important;
    bottom: 100%;
    background-color: white;
    border: 1px solid #eff2f7;
    border-radius: 10px;

    & .img-container {
        border-radius: 10px;
        & img {
            border-radius: 10px;
        }
        &__div1212 {
            height: 100px;
            width: 100px;
        }
    }

    & .chat-image-progress {
        position: absolute;
        width: 100%;
        top: 50%;
        z-index: 9999;
    }

    & img.blur {
        opacity: 0.7;
    }
}
// @media screen and (max-width: 1390px) and (min-width: 1050px) {
// }
@media screen and (max-width: 780px) {
    .active-chat {
        &__wrapper {
            height: calc(100vh - 212px);
        }
        &__body {
            height: calc(100vh - 335px) !important;
        }
    }
    .mobile-wrapper {
        .active-chat {
            &__info {
                width: 100%;
                border: none;
            }
            &__message {
                display: none;
            }
        }
    }
    .mobile-wrapper-detail {
        .active-chat {
            &__info {
                display: none;
            }
            &__message {
                width: 100%;
            }
        }
    }
    .right-border {
        border: none;
    }
    // .badge {
    //     left: 38%;
    // }
    .badge-2 {
        left: 66%;
    }
}
