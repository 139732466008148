.removeModal {
    width: 475px;
    height: 432px;
    border-radius: 13px;
}
.remove__modal {
    h4 {
        font-weight: normal;
        font-size: 22px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #3b3b3b;
        max-width: 353px;
        margin: 0 auto;
    }
    p {
        font-weight: normal;
        font-size: 15px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #3b3b3b;
    }
    button {
        min-width: 80px;
        height: 38px;
    }
    .reason-checkox {
        display: block;
        label {
            font-weight: normal;
            font-size: 17px;
            line-height: 30px;
            color: #3b3b3b;
            margin: 0 auto;
            max-width: 287px;
        }
        li {
            padding-bottom: 15px;
            display: block;
        }
    }
    .styled-checkbox {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it

        & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
        }

        // Box.
        & + label:before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 17px;
            height: 17px;
            background: #ffffff;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
            border-radius: 50%;
        }

        // Box hover
        &:hover + label:before {
            background: #241762;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        }

        // Box focus
        &:focus + label:before {
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }

        // Box checked
        &:checked + label:before {
            background: #241762;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        }
        &:checked + label:after {
            content: "";
            position: absolute;
            left: 4px;
            top: 9px;
            background-image: url("../../assets/images/checked.png");
            width: 11px;
            height: 25px;
            background-repeat: no-repeat;
        }
    }
}
