.faq-wrapper {
    background: #ffffff;
    border-radius: 30px;
    max-width: 977px;
    width: 100%;
    box-shadow: 0 6px 16px #efefef;
    margin: 0 0 50px auto;
    .accordion__button {
        position: relative;
        padding: 21px 60px 21px 35px;
        background: transparent;
        border-bottom: 1px solid #bdbdbd;
        color: #3b3b3b;
        box-shadow: none !important;
        h2 {
            font-size: 22px !important;
            font-weight: normal;
            margin: 0;
        }

        &:hover {
            background: transparent;
        }
        &::before {
            position: absolute;
            right: 35px;
            top: 38%;
            color: #bdbdbd;
        }
    }
    .accordion__panel {
        border-bottom: 1px solid #bdbdbd;
        padding: 20px 20px 20px 60px;
        P {
            font-weight: normal;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.24px;
            color: #3b3b3b;
            margin-bottom: 4px;
        }
    }
    .accordion {
        border: none;
        border-radius: 0;
    }
    .accordion__button[aria-expanded="true"] {
        border-bottom: none;
        h2 {
            color: #6246ea;
        }
        &::before {
            color: #6246ea;
        }
    }
}
.accordion__button:focus-visible {
    outline: none !important;
}
.accordion__button:focus {
    outline: none !important;
}
.faq-icon {
    font-size: 8px;
    color: #6246ea;
}
@media screen and (max-width: 680px) {
    .faq-wrapper {
        .accordion__button {
            h2 {
                font-size: 15px !important;
                max-width: 250px;
            }
        }
        .accordion__panel {
            padding: 20px;
            p {
                font-size: 14px;
            }
        }
    }
}
