.comment__info {
    &-remarks {
        max-width: 576px;
        width: 100%;
        height: 219px;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        padding: 15px;
        h2 {
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #3b3b3b;
        }
        p {
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #3b3b3b;
        }
    }
}
