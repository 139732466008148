.upload-wrapper {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    .camera {
        position: absolute;
        bottom: -20px;
        right: -20px;
        cursor: pointer;
        height: 40px;
        width: 40px;
    }
    .image-upload {
        width: 210px;
        height: 210px;
        border-radius: 15px;
    }
    .image-upload-active {
        width: 145px;
        height: 145px;
        border-radius: 15px;
    }
}
.setupModal {
    max-width: 384px !important;
    // height: 435px;
    width: 85% !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) !important;
    border-radius: 15px !important;
}
