.primary-background {
    // background-image: url("../../assets/images/background.png");
    background-color: white;
    position: relative;
    min-height: 100vh;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
        content: "";
        background-image: url("../../assets/images/upper-bg.png");
        width: 100%;
        height: 357px;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        background-position: left top;
        top: 0;
    }
    &:after {
        content: "";
        background-image: url("../../assets/images/lower-bg.png");
        width: 100%;
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right bottom;
        position: absolute;
        bottom: 0;
        top: initial;
    }
}

.bg-secondary {
    background-color: $secondary !important;
}

.text-primary {
    color: $primary !important;
}

.f-14 {
    font-size: 14px;
}

.pointer {
    cursor: pointer;
}

.text-black {
    color: #000;
}

.info-title {
    font-weight: normal;
    font-size: 15px;
    line-height: 110%;
    color: #000000;
    text-transform: capitalize;
}
.react-responsive-modal-overlay {
    background: rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(1px);
}
.react-responsive-modal-modal {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.justify-content-evenly {
    justify-content: space-evenly;
}
.selected-flag:hover,
.selected-flag:active,
.selected-flag:focus {
    background: #f2f2f2 !important;
    border-radius: 3px 0 0 3px;
}
.back-icon {
    color: #6246ea;
    font-size: 20px;
    cursor: pointer;
}
.match__icon {
    width: 26px;
    height: 26px;
}

// .react-joyride__overlay {
//     display: none;
// }
.sc-gsDJrp.dypMyx {
    overflow: hidden;
}

@media screen and (max-width: 680px) {
    .input-icon {
        top: 20px;
        left: 16px;
    }
    .match__icon {
        width: 20px !important;
        height: 20px !important;
    }
}

textarea {
    resize: none;
}
