.userModal {
    max-width: 620px;
    height: 337px;
    border-radius: 15px;
    width: 380px;
}
.user__modal {
    h4 {
        font-weight: normal;
        font-size: 17px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #3b3b3b;
        max-width: 353px;
        margin: 0 auto;
    }
}

.perivious__modal {
    h4 {
        color: #6246ea;
        font-weight: normal;
        font-size: 17px;
        text-transform: capitalize;
    }
}
.previousModal {
    min-height: 239px !important;
    max-width: 565px !important;
    width: 90%;
    border-radius: 15px;
    padding: 40px 20px;
    margin:  auto;
}