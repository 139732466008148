.credientals-modal {
    display: flex;
    flex-direction: column;
    height: 100%;

    p {
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: #382745;

        max-width: 265px;
        margin: 0 auto 40px auto;
    }
    button {
        min-width: fit-content !important;
    }
}
.verification-send {
    p {
        font-weight: normal;
        font-size: 17px;
        line-height: 18px;
        text-align: center;
        color: #000000;
        max-width: 180px;
        margin: 0 auto;
        line-height: 20px;
    }
}
.verification-code {
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #3b3b3b;
    padding-top: 35px;
    .styles_react-code-input__CRulA input {
        border: 2px solid #6246ea !important;
        border-radius: 5px;
        width: 32px !important;
        height: 41px !important;
    }
    .styles_react-code-input__CRulA input:not(:last-child) {
        margin-right: 15px !important;
    }
    .error-input .styles_react-code-input__CRulA input {
        border-color: red !important;
    }
    a {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.24px;
        text-decoration-line: underline;
        color: #3b3b3b;
        padding-top: 20px;
    }
}
.styles_react-code-input-container__tpiKG.undefined {
    width: 100% !important;
}

.ChangeCredientals {
    max-width: 446px;
    width: 90%;
    height: 190px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
}
.verificationSend {
    max-width: 303px;
    width: 90%;
    height: 195px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
}
.verificationCode {
    max-width: 563px;
    width: 90%;
    height: 504px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
}
