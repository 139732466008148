.nav-block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    // transition: all .3s;
    position: relative;
    // transition: all 0.3s;
    background-color: transparent;
    margin: 2px 0;

    height: 57px;
    // margin-left: 10px;

    .nav-link {
        color: #ffffff;
        font-size: 20px;
        transition: all 0.3s;
        text-transform: uppercase;
    }
    svg {
        color: white;
        font-size: 22px;
    }
}

.active-navlink {
    .masking {
        position: relative;
        &::before {
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            background-color: #6346e9;
            border-radius: 50%;
            bottom: -18px;
            right: -1px;
            z-index: 1;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: -5px;
            right: 0;
            width: 0;
            height: 0;
            border-right: 10px solid white;
            border-bottom: 10px solid transparent;
        }
    }
    .nav-block {
        background-color: #fff;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        margin-left: 5px;
        padding-left: 15px;
        position: relative;
        &::before {
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            background-color: #6346e9;
            border-radius: 50%;
            top: -20px;
            right: 0px;
            z-index: 1;
        }
        &::after {
            content: "";
            position: absolute;
            top: -10px;
            right: 0;
            width: 0;
            height: 0;
            border-bottom: 10px solid white;
            border-left: 10px solid transparent;
        }
        svg {
            color: #e45858;
        }
    }
}

.active-navlink .icon-change path {
    stroke: #e45858;
}
.activate .icon-change path {
    stroke: #e45858;
}
.icon-change path {
    stroke: #fff;
}
