@import "../../theme/main.scss";

.user-deatil {
    &__wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        height: 660px;
        overflow: auto;
        max-width: 574px;
        width: 90%;
    }
    &__info {
        // padding: 20px;
        &-img {
            img {
                width: 93px;
                height: 97px;
            }
        }
        &-detail {
            h3 {
                color: #241762;
                font-weight: normal;
                font-size: 22px;
                font-family: $secondary-font-family !important;
                margin: 0;
            }
            p {
                font-size: 15px;
                color: #000000;
                margin: 0;
                font-weight: normal;
            }
            .match-per {
                font-weight: normal;
                font-size: 15px;
                line-height: 110%;
                color: #e45858;
            }
            border-right: 1px solid #dddddd;
            padding-right: 35px;
        }
        &-stats {
            P {
                font-size: 15px;
                color: #000000;
                margin: 0;
                font-weight: normal;
            }
        }
    }
    &__about {
        padding: 15px 15px 0 15px;
        h2 {
            font-weight: normal;
            font-size: 17px;
            line-height: 110%;
            color: #6246ea;
        }
        P {
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
        }
    }
}
.profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 10%;
}
@media screen and (max-width: 650px) {
    .user-deatil {
        &__info {
            &-detail {
                padding-right: 0 !important;
                border: none !important;
                text-align: center;
            }
            &-stats {
                P {
                    font-size: 12px;
                    text-align: center;
                }
            }
        }
    }
}
