.chat-profile {
    &:not(:first-child) {
        margin-left: 50px;
    }
    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
    h4 {
        color: #444444;
        font-weight: 500;
        font-size: 17px;
        text-transform: capitalize;
    }
    a {
        color: #444444;
        font-size: 15px;
        font-weight: 300;
        background: transparent;
        border: none;
        &:focus {
            outline: none;
        }
    }
    .image-blur {
        filter: opacity(0.4);
    }
}
.exit__modal {
    height: 100%;
    h4 {
        color: #3b3b3b;
        font-size: 17px;
    }
    button {
        min-width: fit-content !important;
        padding: 0 30px;
    }
}
.exitModal {
    max-width: 334px !important;
    height: 163px !important;
    width: 90%;
    border-radius: 15px;
}

.selected {
    border-width: 2px;
    border-color: blueviolet;
    border-style: solid;
}
