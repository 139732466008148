.sidebaropen {
    transition: all 0.3s;
    position: fixed;
    top: 0;
    background-image: url("../../assets/images/sidebar.png");
    width: 80px;
    height: 100vh;
    overflow: auto;
    left: 0;
    z-index: 9;
    // padding-left: 5px;
}

.navigation {
    height: 100vh;
    overflow: hidden;

    &__1 {
        padding-top: 100px;
        height: 80%;
    }

    .nav-block {
        .nav-link {
            white-space: nowrap;
            opacity: 1;
            transition: 0.3s;
            transition-delay: 0;
            &:empty {
                padding: 0;
            }
        }
    }
}

.setting-btn {
    border: none;
    box-shadow: none;
    background: transparent;
    color: #fff !important;
    font-size: 22px;
    // margin-left: 10px;
    width: 100%;
    padding: 8px 20px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 57px;
}
.active {
    background-color: #d1d1e9;
    color: #fb6666 !important;
}
.setting-tour {
    padding-right: 390px;
}
.setting-sub-element {
    width: 230px;
    height: 393px;
    background: #d1d1e9;
    padding: 30px 20px 20px 20px;
    z-index: 999;
    position: fixed;
    top: 48vh;
    left: 78px;
    border-radius: 10px;
    a {
        font-weight: normal;
        font-size: 22px;
        line-height: 18px;
        color: #828282 !important;
        text-decoration: none;
        &:active,
        &:focus {
            color: #6246ea !important;
        }
    }
    svg {
        color: #828282 !important;
        font-size: 16px;
    }
    &__item {
        padding-bottom: 37px;
    }
}
@media screen and (max-width: 1100px) {
    .mobile-header {
        position: fixed;
        width: 100%;
        z-index: 999;
        background-color: #fff;
        height: 70px;
    }
    .sidebaropen {
        position: fixed;
        top: 70px;
        left: 0;
        transition: all 0.3s;
        transform: translateX(-320px);
        opacity: 0;
        visibility: hidden;
        width: 80%;
        max-width: 70px;
        overflow-y: auto;
        display: none;

        &.active {
            transform: translateX(0) !important;
            opacity: 1 !important;
            visibility: visible !important;
            z-index: 999;
            display: block;
        }
    }
    .toggle-btn {
        background: transparent;
        color: #fb6666;
        box-shadow: none;
        border: none;
        font-size: 30px;
        padding-left: 20px;
        &:focus {
            outline: none;
            color: #fb6666;
        }
    }
    .sidebar-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.8);
        z-index: 99;
    }
    .navigation {
        &__1 {
            padding-top: 40px;
            height: auto;
        }
        &:after {
            display: none;
        }
    }
    .setting-sub-element {
        left: 70px;
    }
}
.header-logo {
    width: 150px;
}
