.confirmsuspensionmodal {
    max-width: 475px;
    width: 90%;
    height: 328px;
    border-radius: 13px;
}
.confirmsuspend {
    p {
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #3b3b3b;
    }
    button {
        background: #e4e4fc;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        min-width: 83px !important;
        height: 50px !important;
    }
    .main-description {
        max-width: 290px;
    }
}

.unfoundmodal {
    width: 475px;
    border-radius: 13px;
}

