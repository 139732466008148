@import "../../theme/main.scss";

.hc-text-input {
    border: none !important;
    color: #828282 !important;
    border-radius: 15px !important;
    background-color: #f2f2f2 !important;
    text-align: center;
    position: relative;
    height: 55px !important;
    font-weight: normal;
    font-family: $primary-font-family;
    font-size: 17px !important;

    &::placeholder {
        text-align: center;
    }
    &:focus {
        outline: none !important;
    }
}
.form-control {
    padding: 0 40px 0 40px !important;
    height: 55px !important;
    border: none !important;

    &:focus {
        outline: none;
    }
}
.input-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(35px);
    height: rem(35px);
    background: transparent;
    top: 23px;
    left: 15px;
}

.hc-text-input ~ .invalid-feedback {
    font-size: rem(14px);
    color: red;
}

.pl-40 {
    padding-left: 40px;
}

@media (max-width: 500px) {
    .input-icon {
        top: 3px;
        left: 6px;
    }
    .input-icon img {
        width: 12px;
        height: 12px;
    }
}
