ul.optionContainer {
    display: flex !important;
    flex-direction: column !important;
    max-height: fit-content !important;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 15px 15px;
    border: none;
    li {
        color: #6246ea;
        font-size: 15px;
        padding: 7px 0 7px 35px;
        font-weight: 600 !important;

        &:hover {
            background-color: transparent !important;
            color: #6246ea !important;
        }
    }
}
._7ahQImyV4dj0EpcNOjnwA {
    background: #6246ea;
}
.lhyQmCtWOINviMz0WG_gr {
    background: transparent;
}
._3vLmCG3bB3CM2hhAiQX1tN {
    width: 290px !important;
}
.search-wrapper._2iA8p44d0WZ-WqRBGcAuEV {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border: none;
    padding: 10px;
}
input#search_input::placeholder {
    color: #6246ea !important;
    font-weight: normal;
    font-size: 17px;
}
._1cgu0CqbPSvU9G_sHmk1wv {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    left: 150px;
    color: #6246ea;
    font-size: 20px;
}
._3vt7_Mh4hRCFbp__dFqBCI input {
    padding: 0 0 0 35px;
}
.detailed {
    width: 50%;
}
@media screen and (max-width: 680px) {
    .detailed {
        width: 100% !important;
    }
    ._3vLmCG3bB3CM2hhAiQX1tN {
        width: 80% !important;
    }
}
.dropdown {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    .dropdown-btn {
        background: #ffffff;
        border: none;
        margin-left: 50px;
        border-radius: 0;
        height: 42px;
        font-weight: normal;
        font-size: 17px;
        line-height: 18px;
        color: #6246ea;
        &:after {
            margin-left: 2.255em;
            border: none;
            background-image: url("../../assets/images/Vector.png");
            width: 14px;
            height: 14px;
            background-repeat: no-repeat;
            position: relative;
            top: 7px;
        }
    }
    .dropdown-option-list {
        display: flex;
        flex-direction: column;
        margin-left: 45px;

        li {
            label {
                padding-left: 10px;
                font-weight: normal;
                font-size: 15px;
                line-height: 25px;
                color: #6246ea;
            }
        }
    }
    .dropdown-menu.show {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 15px 15px;
        border: none;
        width: 290px;
        transform: translate3d(10px, 41px, 0px) !important;
    }
    .dropdown-menu.test.show {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 15px 15px;
        border: none;
        width: 290px;
        transform: translate3d(285px, 41px, 0px) !important;
    }
    .styled-checkbox {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it

        & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
        }

        // Box.
        & + label:before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 14px;
            height: 14px;
            background: #ffffff;
            border: 1px solid #6246ea;
        }

        // Box hover
        &:hover + label:before {
            background: #6246ea;
        }

        // Box checked
        &:checked + label:before {
            background: #6246ea;
        }
    }
}
.show {
    .dropdown-btn {
        &:after {
            border: none;
            background-image: url("../../assets/images/Vectordown.png");
            width: 14px;
            height: 14px;
            background-repeat: no-repeat;
            position: relative;
            top: 7px;
        }
    }
}

.loader-padding {
    padding-bottom: 22px;
}

.w-250 {
    width: 250px;
}

@media screen and (max-width: 767px) {
    .dropdown {
        .dropdown-btn {
            margin-left: 20px;
        }
    }
}
