.user-account {
    &__wrapper {
        background: #ffffff;
        border-radius: 30px;
        max-width: 977px;
        width: 100%;
        height: 581px;
        margin: 0 0 50px auto;
        overflow: auto;
        box-shadow: 0 6px 30px #f3f3f3;
        padding-bottom: 30px;
    }
    &__upload {
        p {
            color: #333333;
            font-weight: 500;
            font-size: 22px;
            text-transform: capitalize;
        }
    }
    &__password {
        max-width: 323px;
        width: 100%;
    }
    &__contact {
        max-width: 323px;
        width: 100%;

        & input.custom-inp-error.form-control {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
            background-repeat: no-repeat !important;
            background-position: right calc(0.375em + 0.1875rem) center !important;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
        }

        & .custom-inp-error-message {
            width: 100%;
            margin-top: 0.25rem;
            font-size: 80%;
            color: red;
        }
    }
}
ul.country-list {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
input.form-control {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    margin-bottom: 15px;
    border: none;
    border-radius: 13px;
    backdrop-filter: blur(54.3656px);
}
.react-tel-input .form-control {
    height: 54px !important;
}
.flag-dropdown {
    border: none !important;
    background: transparent !important;
}
.arrow {
    display: none;
}
._1Ou1tAFc1VCD4TNa0XwE7z {
    padding: 0 !important;
}
input.kWobJZ5IBLVJtFBD9JHAQ:focus {
    outline: none !important;
}
.react-tel-input .form-control {
    width: 100%;
    background: #f2f2f2 !important;
    margin-bottom: 15px !important;
    border-radius: 13px;
}
@media screen and (max-width: 680px) {
    .user-account {
        &__wrapper {
            padding: 30px 10px;
        }
    }
}
