.match-card {
    &__wrapper {
        width: 123px;
        height: 72px;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        padding: 5px;
        cursor: pointer;
        overflow: hidden;
        img {
            width: 58px;
            height: 61px;
            border-radius: 15px;
        }
        P {
            font-weight: normal;
            font-size: 17px;
            line-height: 20px;
            color: #000000;
            margin: 0;
            padding-left: 6px;
            &:not(.percentage) {
                width: 58px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .add-icon {
            color: #6246ea;
            font-size: 24px;
            font-weight: 100;
        }
        .percentage {
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 20px;
            color: #6246ea;
            width: 100px;
        }
    }
}
