@import "../../theme/main.scss";

.report {
    &__wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        height: 110px;
        min-width: 1260px;
        padding: 0 20px;
        margin: 0 10px 30px 10px;
        overflow: auto;
    }
    &__data {
        width: 10%;
        border-right: 1px solid #bdbdbd;
        padding: 20px 0;
        p {
            font-weight: normal;
            font-size: 15px;
            line-height: 110%;
            color: #3b3b3b;
            margin: 0;
        }
    }
    &__user {
        padding: 0 20px;
        width: 20%;
        border-right: 1px solid #bdbdbd;
        img {
            width: 58px;
            height: 61px;
            border-radius: 15px;
        }
        p {
            font-weight: normal;
            font-size: 15px;
            line-height: 110%;
            color: #3b3b3b;
            margin: 0;
            font-family: $secondary-font-family !important;
        }
    }
    &__cause {
        width: 20%;
        padding: 20px 0;
        border-right: 1px solid #bdbdbd;
        P {
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            color: #3b3b3b;
            margin: 0;
        }
    }
    &__status {
        width: 15%;
        padding: 20px 0;
        border-right: 1px solid #bdbdbd;
        p {
            font-weight: normal;
            font-size: 15px;
            line-height: 110%;
            color: #3b3b3b;
            margin: 0;
        }
        .status-icon {
            font-size: 9px;
        }
    }
    &__action {
        width: 15%;
        a {
            min-width: fit-content;
        }
    }
}

.new-report {
    color: #e45858;
}
.under-review {
    color: #6246ea;
}
.appeal-request {
    color: #f2c94c;
}
.resolved {
    color: #6fcf97;
}

@media screen and (max-width: 650px) {
    .report {
        &__wrapper {
            height: 85px;
        }
    }
}
