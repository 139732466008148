.CustomModal {
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    background-image: url("../../assets/images/Translucent.png");
    max-width: 475px;
    width: 90%;
    height: 328px;
}
.custom-modal {
    P {
        font-weight: normal;
        font-size: 22px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #3b3b3b;
        max-width: 250px;
    }
    button {
        background: #6246ea;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #e4e4fc;
        padding: 10px 25px;
        border: none;
        &:focus {
            outline: none;
        }
    }
}
