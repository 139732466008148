.terms {
    background: #ffffff;
    border-radius: 30px;
    max-width: 977px;
    width: 100%;
    box-shadow: 0 6px 19px #ececec;
    box-sizing: border-box;
    padding: 23px;
    margin: 0 0 50px auto;

    h2 {
        font-weight: normal;
        font-size: 22px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #000000;
        padding-left: 20px;
    }
    P {
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #000000;
        padding: 20px 19px;
        margin-bottom: 0;
    }
    &-wrapper {
        border: 2px solid #bdbdbd;
        padding: 20px;
        border-radius: 30px;
        &__content {
            height: 542px;
            padding-right: 20px;
        }
    }
}
@media screen and (max-width: 680px) {
    .terms__wrapper {
        padding: 10px;
        margin: 0 0 20px auto;
    }
}
