.commentModal {
    border-radius: 13px;
    max-width: 620px;
    width: 90%;
    height: 537px;
    overflow: auto;
}
.comment__modal {
    h4 {
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        color: #3b3b3b;
    }
    textarea {
        background: #ffffff;
        border: 1px solid #dddddd !important;
        box-sizing: border-box;
        border-radius: 15px;
        padding: 15px !important;
        font-weight: 500;
        font-size: 14px;
        height: 256px !important;
        color: #3b3b3b;
        max-width: 525px;
        width: 100%;
        margin-bottom: 0px !important;
        &:focus,
        &:active {
            outline: none;
        }
        &::placeholder {
            color: #c4c4c4;
        }
    }
}
