.chat-datetime {
    color: #000000;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    padding: 20px 0;
}
