@import "../../theme/main.scss";

.profile-card {
    &__wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        max-width: 481px;
        width: 100%;
        height: 137px;
        padding: 13px 16px;
        margin-bottom: 30px;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        margin-right: 10px;
        margin-left: 40px !important;
        scroll-behavior: smooth;
        &:hover {
            background: #e4e4fc;
            transition: all 0.2s ease-in-out;
        }
    }
    &__profile {
        border-right: 1px solid #979797;
        width: 50%;
        img {
            width: 104px;
            height: 109px;
        }
        P {
            color: #241762;
            font-weight: normal;
            font-size: 22px;
            font-family: $secondary-font-family;
            margin: 0;
        }
    }
    &__info {
        width: 45%;
        P {
            font-size: 15px;
            color: #000000;
            margin: 0;
            font-weight: normal;
        }
    }
}
.spacer {
    margin-bottom: 12px;
}
.active-chat-icon path {
    stroke: #6246ea;
}

.selectedCard {
    background-color: #e4e4fc;
}

@media screen and (max-width: 767px) {
    .profile-card {
        &__wrapper {
            margin-left: 0px !important;
        }
    }
}

@media screen and (max-width: 650px) {
    .profile-card {
        &__wrapper {
            margin-left: 0px;
        }
        &__profile {
            img {
                width: 50px;
                height: 50px;
            }
            p {
                font-size: 18px;
            }
        }
        &__info {
            p {
                font-size: 11px;
            }
        }
    }
}
