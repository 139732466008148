@import "../../theme/main.scss";

.chat-header {
    width: 95%;
    &__images {
        img {
            max-width: 45px;
            max-height: 45px;
        }
        div {
            height: 45px;
            width: 45px;
        }
        :last-child {
            margin-left: -10px;
        }
    }
    &__content {
        h3 {
            color: #000000;
            font-size: 18px;
            font-family: $primary-font-family;
            text-transform: capitalize;
            margin: 0;
        }
    }
    &__setting {
        img {
            cursor: pointer;
        }
    }
}
.perivious__modal {
    h4 {
        color: #6246ea;
        font-weight: normal;
        font-size: 17px;
        text-transform: capitalize;
    }
}
.previousModal {
    min-height: 239px !important;
    max-width: 565px !important;
    width: 90%;
    border-radius: 15px;
    padding: 40px 20px;
}
button.react-responsive-modal-closeButton:focus {
    outline: none !important;
}

.unfoundmodal {
    width: 475px;
    border-radius: 13px;
}
.user-error-container {
    p {
        margin-bottom: 0px;
        margin-top: 10px;
        color: red;
        font-size: 80%;
    }
}
