.reported {
    cursor: pointer;
    &__info {
        min-width: 1260px;
        height: 42px;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        margin: 0 10px 30px 10px;
        padding: 0 20px;
        cursor: pointer;

        p {
            font-weight: normal;
            font-size: 17px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #6246ea;
            margin: 0;
        }
        .reported-icon {
            color: #6246ea;
        }
    }
    &__date {
        width: 10%;
    }
    &__user {
        width: 20%;
    }
    &__cause {
        width: 20%;
    }
    &__by {
        width: 20%;
    }
    &__status {
        width: 15%;
    }
    &__action {
        width: 15%;
    }
}
.report-table {
    overflow: auto;
}

.loader-padding {
    padding-bottom: 22px;
}
