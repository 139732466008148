.content-container {
    display: flex;
    align-items: center;
    justify-content: center;

    & p {
        margin-bottom: 0;
        margin-right: 15px;
    }
}

.content-input-container {
    & .form-group {
        margin-bottom: 0;
    }
    & .form-group input {
        margin-bottom: 0;
    }

    & .content-btn-container {
        position: relative;
        right: -20px;
        display: flex;
        align-items: center;
    }
}

.content-btn {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 30px;
    font-size: 14px;
    height: 30px;
    border-radius: 5px;
    background-color: transparent;
    margin-right: 8px;
    margin-top: 3px;
    color: #6346e9;
    transition: 0.2s all;
    z-index: 99;
    // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    //     0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.content-btn:hover {
    cursor: pointer;
    background-color: #e2e3e4;
}
