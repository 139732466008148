.receiver-chat {
    margin: 15px 0 15px 50px;
    img {
        width: 37px;
        height: 37px;
        border-radius: 50px;
    }
    &__content {
        background: #f7f7f7;
        border-radius: 15px;
        border-bottom-left-radius: 0;
        max-width: 410px;
        // width: 80%;
        padding: 20px;
        margin-left: 10px;
        h2 {
            color: #e45858;
            font-size: 15px;
            text-transform: capitalize;
        }
        p {
            word-break: break-all;
            color: #000000;
            font-size: 17px;
            font-weight: normal;
            margin: 0;
        }
    }
    &__image {
        margin-left: 10px;
        width: 200px;
        border: 3px solid #d2d2d2;
        border-radius: 15px;

        img {
            width: 195px;
            height: 200px;
            border-radius: 10px;
        }
        figure {
            margin: 0;
        }
        .caption {
            font-size: 16px;
            font-weight: normal;
            background-color: #f7f7f7;
            color: #000000;
            word-break: break-all;
            padding: 5px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }
}
.caption-date {
    text-align: right;
    font-size: 11px;
    margin-top: 5px;
    margin-bottom: 0;
}

.report-img {
    height: 100px !important;
    width: 100px !important;
}

.chat-img-container {
    width: 106px !important;
    height: fit-content;
    margin-top: 5px !important;
    .caption {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 980px) {
    .receiver-chat {
        margin: 15px 0 15px 15px;

        &__content {
            padding: 10px;
            h2 {
                font-size: 14px;
            }
            p {
                font-size: 14px;
                word-break: break-all;
            }
        }
        &__image {
            width: 220px;
            height: fit-content;
            .caption {
                font-size: 14px;
            }
        }
    }
}
