.about__wrapper {
    background: #ffffff;
    border-radius: 30px;
    max-width: 977px;
    width: 100%;
    padding: 60px 30px;
    box-shadow: 0 6px 30px #f1f1f1;
    margin: 0 0 50px auto;
    h2 {
        font-weight: bold;
        font-size: 22px;
        line-height: 35px;
        letter-spacing: -0.24px;
        color: #3b3b3b;
    }
    P {
        font-weight: normal;
        font-size: 17px;
        line-height: 35px;
        letter-spacing: -0.24px;
        color: #3b3b3b;
        max-width: 574px;
    }
}
@media screen and (max-width: 680px) {
    .about__wrapper {
        margin: 0 auto 50px auto;
        padding: 50px 10px;
    }
}
