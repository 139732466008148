.match-filter {
    .filter-list {
        max-width: 375px;
        width: 95%;
    }
    ul.filter-list li {
        display: inline;
    }
    ul.filter-list li label {
        display: inline-block;
        background-color: transparent;
        border: 1px solid #9d9d9d;
        color: #9d9d9d;
        border-radius: 15px;
        white-space: nowrap;
        margin: 3px 5px 10px 5px;
        font-weight: normal;
        font-size: 17px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        transition: all 0.2s;
    }

    ul.filter-list li label {
        padding: 5px 12px;
        cursor: pointer;
    }

    ul.filter-list li input[type="checkbox"] {
        display: absolute;
    }
    ul.filter-list li input[type="checkbox"] {
        position: absolute;
        opacity: 0;
    }

    .filter-selected {
        border: 1px solid #6246ea !important;
        color: #6246ea !important;
        transition: all 0.2s;
    }
}
