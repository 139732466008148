.notification {
    &__wrapper {
        max-width: 977px;
        width: 100%;
        height: 581px;
        background: #ffffff;
        border-radius: 30px;
        box-shadow: 0 6px 15px #e6e6e6;
        margin: 0 0 50px auto;
        padding: 50px 20px 20px 20px;
    }
    &__info {
        margin-bottom: 50px;
        h2 {
            font-weight: normal;
            font-size: 22px;
            letter-spacing: -0.24px;
            color: #000000;
            width: 30%;
            padding-left: 140px;
        }
        &-contant {
            width: 60%;
        }
        &-btn {
            width: 10%;
        }
        &-toggle {
            width: 70%;

            p {
                font-weight: normal;
                font-size: 17px;
                line-height: 17px;
                letter-spacing: -0.24px;
                text-transform: capitalize;
                color: #333333;
                margin: 0;
                padding-bottom: 30px;
            }
        }
    }
}
.notification__info-content {
    width: 60%;
}
@media screen and (max-width: 780px) {
    .notification {
        &__wrapper {
            margin: 0 auto 50px auto;
            padding: 20px;
        }
        &__info {
            h2 {
                padding-left: 0;
            }
            &-toggle {
                width: 100%;
            }
            &-content {
                width: 80%;
            }
        }
    }
}
