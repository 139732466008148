.main-wrapper {
    margin-left: 110px;
    padding-right: 40px;
    z-index: 9;
    position: relative;
    min-height: 100vh;
    img {
        cursor: pointer;
    }
}
.logo {
    max-width: 250px;
}
.main-header {
    padding: 30px 0;
}
@media screen and (max-width: 1100px) {
    .main-wrapper {
        margin-left: 20px;
        padding-right: 20px;
        padding-top: 110px;
        .logo {
            width: 150px;
        }
    }
}
