.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 45px;
  min-width: 160px;
  font-size: 20px;
  outline: none;
  border-radius: 3px;
  .loading {
    animation: spin 2s linear infinite;
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
  &.btn-round {
    border-radius: 25px !important;
  }
  &.btn-small {
    height: 35px;
    min-width: 100px;
    font-size: 14px;
  }
}
.btn-primary {
  background-color: #e4e4fc !important;
  border-color: #e4e4fc !important;
  font-size: 18px !important;
  color: #6246ea !important;
  box-shadow: 0px 4px 10px rgba(163, 111, 111, 0.15) !important;
  border-radius: 15px !important;
  &:focus,
  &:active,
  &:hover {
    outline: none !important;
  }
}

.btn-dark {
  background-color: $black;
  border-color: $black;
}
