.loader {
    height: 20vh;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
        position: absolute;
        height: 30%;
        animation: bounce 2s alternate ease-in-out infinite;
    }
}
.circular {
    animation: rotate 2s linear infinite;
    height: 100%;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #fff;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
@keyframes bounce {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}

// demo-specific
body {
    background-color: #fff;
}
.showbox {
    position: fixed;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(#000, 0.8);
    // background: rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(4px);
}
