@import "../../theme/main.scss";

.notification-card {
    &__wrapper {
        height: 100px;
        border-bottom: 1px solid #f2f2f2;
        padding-top: 17px;

        &:hover {
            background-color: #e4e4fc;
            cursor: pointer;
        }
    }
    &__img {
        width: 58px;
        height: 61px;
        border-radius: 15px;
    }
    &__info {
        h5 {
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            color: #3b3b3b;
            mix-blend-mode: normal;
            opacity: 0.8;
            margin: 0;
        }
        P {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #828282;
            margin: 0;
        }
        h6 {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #828282;
        }
    }
}

.notification__title {
    height: 75px;
    display: flex;
    align-items: center;
    p {
        font-weight: normal;
        font-size: 22px;
        line-height: 110%;
        color: #3b3b3b;
        font-family: $secondary-font-family !important;
        padding-left: 30px;
        margin: 0;
    }
}
.info {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
}
.notification__footer {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 0;
    background: rgba(255, 255, 255, 1);

    button {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #3b3b3b;
        margin: 0;
        cursor: pointer;
        background-color: transparent;
        border: none;
    }
}

.vh-8 {
    height: 527px;
}

.simplebar-wrapper {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    border-radius: 15px;
    border: none;
    width: 369px !important;
    padding: 0;

    z-index: 3;
    max-height: 526px !important;
    box-shadow: 0 9px 16px #e2e0e0;
}
[data-simplebar] {
    position: absolute !important;
    z-index: 9999999;
    top: 95px;
    right: 30px;
}

@media screen and (max-width: 767px) {
    .simplebar-wrapper {
        width: 280px !important;
        max-height: 336px !important;
    }
}

@media screen and (max-width: 680px) {
    [data-simplebar] {
        top: 75px;
        right: 0;
    }
}
