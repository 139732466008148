.search-box {
    width: 100%;
    line-height: 0;
    input {
        max-width: 355px;
        width: 90%;
        color: #6246ea;
        background: #ffffff;
        border: 1px solid #6246ea;
        box-sizing: border-box;
        border-radius: 40px;
        padding: 5px 15px;
        font-size: 17px;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: rgba(98, 70, 234, 0.5);
            text-transform: capitalize;
            font-size: 17px;
            font-weight: normal;
            line-height: 18px;
        }
    }
}
