@import "../../theme/main.scss";
.page-title {
    color: #6246ea;
    font-weight: normal;
    font-size: 22px;
    line-height: 18px;
    border: 1px solid #6246ea;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
    border-radius: 15px;
    font-family: $secondary-font-family;
    display: flex;
    align-items: center;
    height: 47px;
    // text-transform: capitalize;
    padding-left: 50px;
    margin-bottom: 20px;
    text-decoration: none !important;
    &:hover,
    &:active,
    &:focus {
        color: #6246ea !important;
    }
}
