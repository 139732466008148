.contact__wrapper {
    background: #ffffff;
    border-radius: 30px;
    max-width: 977px;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 30px 60px 50px 60px;
    box-shadow: 0 6px 15px #dedede;
    margin: 0 0 50px auto;
    h2 {
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;

        color: #444444;
    }
    .contact_form {
        background: rgba(255, 255, 255, 0.5) !important;
        border: 1.5px solid #dddddd !important;
        color: #3b3b3b !important;
        font-size: 17px !important;
        padding: 0px 30px !important;
        border-radius: 15px;
    }
    .text-area {
        height: 260px !important;
        overflow: auto !important;
        padding: 15px !important;
    }
    .invalid-feedback {
        text-align: start;
    }
}
@media screen and (max-width: 680px) {
    .contact__wrapper {
        padding: 30px 10px;
        margin: 0 auto 50px auto;
        h2 {
            text-align: center;
        }
    }
}
