*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
}

body {
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: $primary-font-family !important;
    font-size: 1rem;
    font-weight: 600;
    color: $text-color;
    background-color: #fff;
}

.container-p-l {
    padding-left: 10%;
}

.container-p-r {
    padding-right: 10%;
}

.container-m-l {
    margin-left: 10%;
}

.container-m-r {
    margin-right: 10%;
}

a,
button {
    color: inherit;
    text-decoration: none;
    &:focus,
    &:active {
        color: inherit;
        outline: none;
    }
    &:hover {
        cursor: pointer;
    }
}

a {
    transition: all 0.3s ease-in-out;
    &:hover {
        color: $primary;
    }
}
ul {
    list-style: none;
    li {
        display: inline-block;
    }
}
