.sender-chat {
    margin: 15px 30px 15px 0px;
    .sender-profile {
        width: 37px;
        height: 37px;
        border-radius: 50px;
    }
    &__content {
        background: #6246ea;
        border-radius: 15px;
        border-bottom-right-radius: 0;
        max-width: fit-content;
        width: 80%;
        padding: 20px;
        margin-right: 10px;

        p {
            color: #ffffff;
            font-size: 17px;
            font-weight: normal;
            margin: 0;
            overflow-wrap: break-word;
        }
    }
    &__image {
        margin-right: 10px;
        width: 200px;
        border: 3px solid #6246ea;

        border-radius: 15px;

        img {
            width: 194px;
            height: 200px;
            border-radius: 10px;
            object-fit: contain;
        }
        figure {
            margin: 0;
        }
        .caption {
            font-size: 16px;
            font-weight: normal;
            color: #fff;
            // border-radius: 10px;
            background-color: #6246ea;
            padding: 8px 5px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }
}

.margin-msg {
    margin-top: 15px !important;
}
.report-img {
    height: 100px !important;
    width: 100px !important;
}
.chat-img-container {
    width: 106px !important;
    height: fit-content;
    margin-top: 5px !important;
    .caption {
        font-size: 12px !important;
    }
}
.caption-date {
    text-align: right !important;
    font-size: 11px !important;
    margin-top: 5px !important;
    margin-bottom: 0 !important;
}

@media screen and (max-width: 980px) {
    .sender-chat {
        margin: 15px 15px 15px 0px;

        &__content {
            padding: 10px;
            h2 {
                font-size: 14px;
            }
            p {
                font-size: 14px;
            }
        }
        &__image {
            width: 220px;
            height: fit-content;
            .caption {
                font-size: 14px;
            }
        }
    }
}
.remove-btn {
    right: -19px;
    top: -22px;
}
