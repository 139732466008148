.logoutModal {
    max-width: 475px;
    width: 90%;
    height: 328px;
    border-radius: 13px;
    background-image: url("../../assets/images/Translucent.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.logout-icon {
    cursor: pointer;
}
.logout__modal {
    h4 {
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #3b3b3b;
        max-width: 271px;
        width: 80%;
    }
    .logout-btn {
        background: #6246ea;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #e4e4fc;
        padding: 15px 30px;
        border: none;
        &:focus {
            outline: none;
        }
    }
}
