.main-title {
    font-weight: normal;
    font-size: 22px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #000000;
}
.description {
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #3b3b3b;
    max-width: 80%;
}
.skip-tour {
    font-weight: 300;
    font-size: 11px;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-decoration-line: underline;
    color: #846ce9;
    margin-top: 20px;
    text-align: center;
}
.gJanfU {
    min-height: 250px;
}
.iObEcR {
    align-self: flex-start !important;
}
.next-btn {
    min-width: 112px !important;
    height: 50px;
    margin-top: 70px !important;
}
#react-joyride-step-9 .__floater__open .__floater__body .__floater__arrow span {
    background-image: url("../../../../assets/images/notificationicon.png") !important;
    width: 41px !important;
    height: 45px !important;
}
#react-joyride-step-9 .__floater__open {
    top: 32px !important;
}
#react-joyride-step-7 .__floater__open {
    top: 25px !important;
    left: 70px !important;
}
.start-btn {
    min-width: 111px;
    height: 50px;
}
.react-joyride__overlay {
    background-color: transparent !important;
}
.react-joyride__spotlight {
    background-color: transparent;
    width: 70px !important;
    margin-left: 20px;
    border-radius: 20px;
    height: 62px;
}
.sc-bdnxRM {
    background: #ffffff !important;
    box-shadow: 0px 4px 10px rgba(177, 177, 177, 0.116) !important;
}
.skip-wrapper {
    margin: 0 0 0 auto;
}
.main-wrapper-img {
    width: 128px;
    height: 142px;
}
.tour-main-img {
    max-width: 100%;
}
.__floater {
    transition: opacity 1s, transform 0s !important;
}

.__floater__arrow {
    top: -26px !important;
    span {
        background-image: url("../../../../assets/images/touricon.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 66px;
        height: 48px;
        left: -48px;
    }
    svg {
        display: none;
    }
}

#react-joyride-step-4,#react-joyride-step-5,#react-joyride-step-6,#react-joyride-step-7,#react-joyride-step-8{
    .sc-eCApnc {
        padding-right: 25px;
    }
    .skip-wrapper {
        .pointer {
            margin-right: 29px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .main-title {
        font-size: 20px;
    }
    .description {
        font-size: 16px;
        max-width: 100%;
    }

    #react-joyride-step-4 .__floater.__floater__open {
        transform: translate3d(10px, 270px, 0px) !important;
    }
    #react-joyride-step-5 .__floater.__floater__open {
        transform: translate3d(10px, 330px, 0px) !important;
    }
    #react-joyride-step-6 .__floater.__floater__open {
        transform: translate3d(10px, 391px, 0px) !important;
    }
    .main-wrapper-img {
        width: 80px;
        height: 80px;
    }
    // .oGomx {
    //     max-width: 100% !important;
    // }
    // .jNhzRB {
    //     max-width: 80% !important;
    // }
    .next-btn {
        margin-top: 30px !important;
    }
    .__floater__arrow {
        top: 2px !important;
        span {
            // background-image: url("../../../../assets/images/notificationicon.png") !important;
            // width: 40px;
            // height: 45px;
            background: none !important;
            left: 0;
            svg {
                display: block;
            }
        }
    }
    #react-joyride-step-9 .__floater__open .__floater__body .__floater__arrow span {
        background: none !important;
    }
    .__floater__body {
        max-width: 90%;
        left: 5%;
        position: relative;
    }
    #react-joyride-step-7 .__floater__open {
        left: 0px !important;
    }
    .jhPrlz {
        padding: 10px !important;
    }
    #react-joyride-step-7 .__floater.__floater__open .__floater__body .__floater__arrow {
        top: auto !important;
        span {
            bottom: -31px !important;
        }
    }
    #react-joyride-step-8 .__floater.__floater__open .__floater__body .__floater__arrow {
        top: auto !important;
    }
    span {
        bottom: -30px !important;
    }
}
