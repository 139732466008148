.setup {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 99;

    &__upload {
        max-width: 332px;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        z-index: 99;
        img {
            cursor: pointer;
            width: 221px;
            height: 218px;
            border-radius: 15px;
        }
        button {
            padding: 0 80px;
        }
        input {
            text-align: left !important;
            padding: 0;
        }
        ._1Fq_tZ_l7SwH_r_a_SNOtA._1zaTZoyOruLA27LomLt6fx {
            width: fit-content;
            margin: 0 auto;
        }
        .YpD9o6H9LydyYmaPWOwt {
            background-image: url("../../assets/images/upload.png") !important;
            background-position: center center !important;
            border: none;
            outline: none;
            background-size: cover !important;
            color: transparent !important;
        }
        ._2oSbenaQCQZPXOD75Sj6nE._3m334JNOLa1lrKCCrXnNDG {
            border: none;
        }
        button.eXEoGG26iwSi6UiZD8FI6 {
            &:focus {
                outline: none !important;
            }
        }
        ul.country-list {
            display: flex;
            flex-direction: column;
            align-items: baseline;
        }
        input.form-control {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            background-color: #f2f2f2;
            margin-bottom: 15px;
            border: none;
            border-radius: 13px;
            backdrop-filter: blur(54.3656px);
        }
        .react-tel-input .form-control {
            height: 54px !important;
        }
        .flag-dropdown {
            border: none !important;
            background: transparent !important;
        }
        .arrow {
            display: none;
        }
        ._1Ou1tAFc1VCD4TNa0XwE7z {
            padding: 0 !important;
        }
        input.kWobJZ5IBLVJtFBD9JHAQ:focus {
            outline: none !important;
        }
    }
    &__modal {
        button {
            min-width: 120px;
        }
    }
    &__complete {
        p {
            font-weight: normal;
            font-size: 17px;
            line-height: 25px;
            color: #3b3b3b;
            letter-spacing: 0.38px;
        }
        a {
            min-width: 257px !important;
        }
    }
}

._1MKAF96uXJHSW-a1nWoriQ svg {
    display: none !important;
}
._1MKAF96uXJHSW-a1nWoriQ {
    &::before {
        content: "";
        background-image: url("../../assets/images/less.png");
        background-repeat: no-repeat;
        height: 30px;
        width: 30px;
        z-index: 9999;
        top: 0px;
        left: -10px;
        position: absolute;
    }
    &:after {
        content: "";
        background-image: url("../../assets/images/add.png");
        background-repeat: no-repeat;
        height: 30px;
        width: 30px;
        z-index: 9999;
        top: 0;
        right: -13px;
        position: absolute;
    }
}
._1Fq_tZ_l7SwH_r_a_SNOtA ._34iiqkc-jTKb3N7DvLLkwC {
    width: 100% !important;
}
._10Kns8R3VdHSGFrESIz-4B {
    display: none !important;
}
._2oSbenaQCQZPXOD75Sj6nE {
    border: 2px solid #868686 !important;
    border-radius: 17px !important;
}
button.eXEoGG26iwSi6UiZD8FI6:focus {
    outline: none;
}
._1Fq_tZ_l7SwH_r_a_SNOtA._1PTKmYTPmkH41HyPQ6Yp1q {
    width: 80%;
    margin: 0 auto;
}

// range
input[type="range"] {
    -webkit-appearance: none;
    margin: 15px auto;
    width: 80%;
}
input[type="range"]:focus {
    outline: none !important;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 90%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    background: #bdbdbd;
    border-radius: 25px;
    border: 0 solid #000101;
    margin-top: 4px;
}
input[type="range"]::-webkit-slider-thumb {
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #6246ea;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}
.z-indx {
    z-index: 99;
}

.setup__upload .react-tel-input .form-control {
    margin-bottom: 0px !important;
}

.error {
    color: red;
    font-size: 80%;
}

.height-auto {
    height: auto !important;
}

.hc-text-input-2 {
    text-align: left !important;

    &::placeholder {
        text-align: left !important;
    }
}
